import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StockFlowReferenceDataService } from '@stock-flow/data-access';
import { SfReferenceDataTypeEnum } from '@stock-flow/models';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { TypeCategoryActions } from './type-category.actions';
import { SelectListItemWithTranslationDto } from '@common/angular/core/models';

@Injectable()
export class TypeCategoryEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeCategoryActions.get),
      exhaustMap((action) =>
        this.service
          .getSelectListByType(
            SfReferenceDataTypeEnum.TypeCategory,
            action.operationId
          )
          .pipe(
            map((items: SelectListItemWithTranslationDto[]) => {
              if (!items) return TypeCategoryActions.error;
              return TypeCategoryActions.getSuccess({ items });
            }),
            catchError(() => of(TypeCategoryActions.error()))
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private service: StockFlowReferenceDataService
  ) {}
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StockFlowReferenceDataService } from '@stock-flow/data-access';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { UsersActions } from './users.actions';
import { SelectListItemDto } from '@common/angular/core/models';

@Injectable()
export class UsersEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.get),
      exhaustMap(() =>
        this.service.getUsers().pipe(
          map((items: SelectListItemDto[]) => {
            if (!items) return UsersActions.error;
            return UsersActions.getSuccess({ items });
          }),
          catchError(() => of(UsersActions.error()))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private service: StockFlowReferenceDataService
  ) {}
}
